// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, postForm } from 'Utils/http';
import { API_URL, STRIPE_URL } from 'Config';
import type { RootState } from 'Store';
import {
  GetCreditCardsStartAction,
  GetCreditCardsSuccessAction,
  GetCreditCardsFailureAction,
  GetSubscriptionStartAction,
  GetSubscriptionSuccessAction,
  GetSubscriptionFailureAction,
  UpdateCreditCardSuccessAction,
  UpdateDefaultCardStartAction,
  UpdateDefaultCardSuccessAction,
  UpdateDefaultCardFailureAction,
  DeleteCreditCardStartAction,
  DeleteCreditCardSuccessAction,
  DeleteCreditCardFailureAction,
  CreatePaymentInstrumentSuccessAction,
  CreatePaymentInstrumentFailureAction,
  CreateCustomerAction,
} from './subscriptionActions';

const parseCreditCardResponse = (creditCard: ICreditCardResponse) => {
  const expMonth = `0${creditCard.exp_month}`.slice(-2);
  const expYear = creditCard.exp_year;

  const result: ICreditCard = {
    name: creditCard.name,
    address_city: creditCard.address_city,
    address_line1: creditCard.address_line1,
    address_line2: creditCard.address_line2,
    address_state: creditCard.address_state,
    address_zip: creditCard.address_zip,
    number: `**** **** **** ${creditCard.last4}`,
    expDate: `${expMonth}/${expYear}`,
    cardType: creditCard.brand,
    id: creditCard.id,
    last4: creditCard.last4,
    defaultCard: creditCard.defaultCard,
  };

  return result;
};

export const GetCreditCardsThunk =
  (stripeId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/subscriptions/getCards?stripeId=${stripeId}`;
    dispatch(GetCreditCardsStartAction());

    const result = await getJson<ICreditCardResponse[]>(url, token);
    if (!result.success) {
      dispatch(GetCreditCardsFailureAction(result.message));
      return;
    }

    const parsedResult = result.data.map(parseCreditCardResponse);
    dispatch(GetCreditCardsSuccessAction(parsedResult));
  };

export const GetOrgSubscriptionThunk =
  (orgId?: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const ownerOrgId = _ADMIN_ ? orgId : getState().account.org._id;
    const url = `${API_URL}/subscriptions/getOrgActiveSubscription/${ownerOrgId}`;
    const { token } = getState().auth;

    dispatch(GetSubscriptionStartAction());
    const result = await getJson<ISubscriptionsResponse>(url, token);

    if (result.success) {
      dispatch(GetSubscriptionSuccessAction(result.data));
    } else {
      dispatch(GetSubscriptionFailureAction(result.message));
    }
    return result;
  };

export const EditCreditCardThunk = (card: ICreditCard) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/subscriptions/updateCardDetails`;
  const { token } = getState().auth;

  const result = await postJson(url, card, token);
  if (result.success) {
    dispatch(UpdateCreditCardSuccessAction(card));
  }

  return result;
};

export const UpdateDefaultCardThunk = (cardId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/subscriptions/setDefaultCard`;
  const { token } = getState().auth;
  const data = { stripeToken: cardId };

  dispatch(UpdateDefaultCardStartAction());

  const result = await postJson<string>(url, data, token);
  if (result.success) {
    dispatch(UpdateDefaultCardSuccessAction(cardId));
  } else {
    dispatch(UpdateDefaultCardFailureAction(result.message));
  }

  return result;
};

export const DeleteCreditCardThunk = (cardId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const url = `${API_URL}/subscriptions/deleteCard`;
  const { token } = getState().auth;
  const data = { id: cardId };

  dispatch(DeleteCreditCardStartAction());

  const result = await postJson<ICreditCardResponse[]>(url, data, token);
  if (!result.success) {
    dispatch(DeleteCreditCardFailureAction(result.message));
    return;
  }

  const parsedResult = result.data.map(parseCreditCardResponse);
  dispatch(DeleteCreditCardSuccessAction(parsedResult));
};

export const CreateCustomer = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/createCustomer`;
  const { token } = getState().auth;

  dispatch(CreateCustomerAction());
  const result = await getJson(url, token);
};

export const CreatePaymentInstrumentThunk = (card: ICreditCard) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const cardTokenUrl = `${STRIPE_URL}/tokens`;
  const cardTokenData = {
    card: {
      name: card.name,
      number: card.number.replace(/\s/g, ''),
      exp_month: card.expDate.split('/')[0],
      exp_year: card.expDate.split('/')[1],
      cvc: card.cvv,
      address_line1: card.address_line1,
      address_line2: card.address_line2,
      address_city: card.address_city,
      address_state: card.address_state,
      address_zip: card.address_zip,
    },
  };

  const creditCardTokenResult = await postForm<any>(
    cardTokenUrl,
    cardTokenData,
    _STRIPE_KEY_,
  );
  if (!creditCardTokenResult.success) {
    dispatch(CreatePaymentInstrumentFailureAction(creditCardTokenResult.message));
    return creditCardTokenResult;
  }

  const url = `${API_URL}/subscriptions/createPaymentInstrument`;
  const { token } = getState().auth;
  const data = { stripeToken: creditCardTokenResult.data.id };

  const createPaymentInstrumentResult = await postJson<ICreditCardResponse>(
    url,
    data,
    token,
  );
  if (!createPaymentInstrumentResult.success) {
    dispatch(CreatePaymentInstrumentFailureAction(createPaymentInstrumentResult.message));
  } else {
    const parsedResponse = parseCreditCardResponse(
      createPaymentInstrumentResult.data,
    );
    dispatch(CreatePaymentInstrumentSuccessAction(parsedResponse));

    await UpdateDefaultCardThunk(parsedResponse.id)(dispatch, getState);
  }
  return createPaymentInstrumentResult;
};
