// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Snackbar, Button, FormGroup, FormControlLabel, Switch, CircularProgress, Dialog, Select, Chip, MenuItem, Checkbox } from '@mui/material';
import { Box, useTheme, ThemeProvider } from '@mui/system';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Alert from '@mui/material/Alert';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import { InputBaseWidth } from 'Utils/themes';
import { Form } from 'react-final-form';
import { TextField, Validators } from 'Components/forms';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetOrgUsersThunk, GetOrgAssignableRolesThunk, EditOrgUserThunk, GetOrgTeamsThunk, CreateOrgUserThunk } from 'Features/account/accountThunk';
import { GetOrgSubscriptionThunk } from 'Features/account/subscriptionThunk';
import stylesDef from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  // @ts-ignore
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '250',
    },
  },
};

const escapeHtmlEntities = (str: string) => {
  return str.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
};

const sanitizeAndEscapeInput = (input: string) => {
  const sanitizedInput = DOMPurify.sanitize(input);
  return escapeHtmlEntities(sanitizedInput);
};

interface ComponentProps {
  showUserForm: boolean;
  setShowUserForm: (show: boolean) => void;
  activeUser: IActiveUser;
  setActiveUser: (activeUser: IActiveUser) => void;
  userFormMode: string;
  pocEmail: string;
  setPocEmail: (pocEmail: string) => void;
}

export const UserForm: React.FC<ComponentProps> = ({ showUserForm, setShowUserForm, pocEmail, setPocEmail, activeUser, setActiveUser, userFormMode }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const orgTeams = useAppSelector((state) => state.account.orgTeams);
  const resources = useAppSelector((state) => state.auth.resources);
  const organizationAccess = _ADMIN_ ? true : resources?.organizationAccess;
  const initialValues = activeUser;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [adminChecked, setAdminChecked] = useState(false);
  // control for the poc toggle switch on the form
  const [pocChecked, setPocChecked] = useState(false);
  // boolean to check if poc is updated for the current user
  const [pocUpdated, setPocUpdated] = useState(false);
  const [adminChanged, setAdminChanged] = useState(false);
  const [teamsChanged, setTeamsChanged] = useState(false);
  const [failedResultMessage, setFailedResultMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableUpdateUserButton, setDisableUpdateUserButton] = useState(true);
  const [updatedRoles, setUpdatedRoles] = useState([]);

  useEffect(() => {
    setAdminChecked(activeUser.rolesArray.includes('admin'));
    setPocChecked(activeUser.email === pocEmail);
    if (!showUserForm) {
      setAdminChecked(false);
      setPocChecked(false);
      setPocUpdated(false);
      setAdminChanged(false);
      setTeamsChanged(false);
      setLoading(false);
      setUpdatedRoles([]);
    } else {
      setShowError(false);
    }
  }, [showUserForm]);

  useEffect(() => {
    if (!organizationAccess) {
      clearLocalStorage();
      history.push('/');
    }
    const loadData = async () => {
      const orgIdInUrl = _ADMIN_ ? orgId : undefined;
      await dispatch(GetOrgTeamsThunk(orgIdInUrl));
      await Promise.all([
        dispatch(GetOrgUsersThunk(orgIdInUrl)),
        dispatch(GetOrgAssignableRolesThunk(orgIdInUrl)),
        dispatch(GetOrgSubscriptionThunk(orgIdInUrl)),
      ]);
    };
    if (!loading) {
      loadData();
    }
  }, [loading]);

  const toggleAdminChecked = () => {
    const stateCopy = [...activeUser.rolesArray];
    if (!adminChecked) {
      stateCopy.push('admin');
    } else {
      const index = stateCopy.indexOf('admin');
      if (index > -1) {
        stateCopy.splice(index, 1);
      }
    }
    setAdminChecked(!adminChecked);
    setUpdatedRoles(stateCopy);
    setActiveUser({ ...activeUser, rolesArray: stateCopy });
    setAdminChanged(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };

  const togglePocChecked = () => {
    setPocChecked(!pocChecked);
    setPocUpdated(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };

  const handleClick = (_id: string, name: string) => {
    const stateCopy = { ...activeUser };
    if (!activeUser.teams.some((el: ITeam) => el._id === _id)) {
      stateCopy.teams = activeUser.teams.concat({ _id, name });
      setActiveUser(stateCopy);
    } else {
      const filteredArray = activeUser.teams.filter((t: ITeam) => t._id !== _id);
      setActiveUser({ ...stateCopy, teams: filteredArray });
    }
    setTeamsChanged(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };
  const sanitizeObject = (obj: any) => {
    const sanitizedObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string') {
        sanitizedObj[key] = sanitizeAndEscapeInput(value);
      } else {
        sanitizedObj[key] = value; // Leave other types unchanged
      }
    }
    return sanitizedObj;
  };
  
  const handleUserFormSubmit = async (values: any) => {
    const sanitizedValues = sanitizeObject(values);
  
    const result = userFormMode === 'add' ? await createUser(sanitizedValues) : await editUser(sanitizedValues);
    if (result.success) {
      dispatch(GetOrgSubscriptionThunk(_ADMIN_ ? orgId : undefined));
    } else {
      setShowError(true);
    }

    setShowUserForm(false);
  };

  const createUser = async (values: any) => {
    setLoading(true);
    const { email, firstName, lastName, teams } = values;

    const result = await dispatch(CreateOrgUserThunk(values, _ADMIN_ ? orgId : undefined));

    if (result.success) {
      window?.pendo?.track('User Created', { first_name: firstName, last_name: lastName, teams, email });
      setShowSuccess(true);
    } else if (!result.success && result.message) {
      setFailedResultMessage(result.message);
    }

    return result;
  };

  const editUser = async (values: any) => {
    setLoading(true);
    const updatedTeams:any = activeUser.teams;
    const updatedUser = {
      ...values,
      updatedRoles: adminChanged ? updatedRoles : undefined,
      updatedTeams: teamsChanged ? updatedTeams : undefined,
    };

    const setUserAsPoc = pocChecked && pocUpdated;
    const result = _ADMIN_ ? await dispatch(EditOrgUserThunk(updatedUser, orgId, setUserAsPoc))
      : await dispatch(EditOrgUserThunk(updatedUser));

    if (result.success) {
      window?.pendo?.track('User Edited', { user_name: activeUser.fullName, email: activeUser.email, teams: activeUser.teams });
      if (_ADMIN_ && result.data.orgUpdated) {
        setPocEmail(activeUser?.email);
      }
    }
    setDisableUpdateUserButton(true);
    setPocUpdated(false);
    return result;
  };

  const handleSuccessSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
  };

  const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const handleOnClose = () => {
    setShowUserForm(false);
    if (userFormMode !== 'add') setDisableUpdateUserButton(true);
  };

  return (
    <>
      <Snackbar sx={styles.snackBar} open={showSuccess} autoHideDuration={10000} onClose={handleSuccessSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success" sx={styles.alert} color="info">Invitation Sent</Alert>
      </Snackbar>
      <Snackbar sx={styles.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" sx={styles.error} color="error">{ !failedResultMessage ? `Unable to ${userFormMode} User` : failedResultMessage }</Alert>
      </Snackbar>
      <Dialog open={showUserForm} onClose={handleOnClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={handleUserFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          render={({ pristine, hasSubmitErrors, submitting, handleSubmit }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" sx={styles.heading}>{userFormMode === 'add' ? 'Add User' : 'Edit User'}</Typography>
              <Box sx={styles.formRow}>
                <TextField
                  label="First Name"
                  name="firstName"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField
                  label="Work Email"
                  name="email"
                  validate={Validators.composeValidators(Validators.required, Validators.email)}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
              </Box>
              <Box sx={styles.formRow}>
                <TextField
                  label="Job Title"
                  name="jobTitle"
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  validate={Validators.mobilePhone}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
              </Box>
              <Typography sx={styles.acceessText} variant="h4">Teams</Typography>
              <ThemeProvider theme={InputBaseWidth}>
                <Select
                  sx={styles.selectTeams}
                  displayEmpty
                  multiple
                  value={activeUser.teams.map((el: ITeam) => el.name)}
                  disableUnderline
                  renderValue={(selected: []) => {
                    if (selected.length === 0) {
                      return <em>Choose one or more teams</em>;
                    }
                    return (
                      <Box sx={styles.chips}>
                        {activeUser.teams.map((team: ITeam) => (
                          <Chip
                            size="medium"
                            onMouseDown={(event: React.MouseEvent) => {
                              event.stopPropagation();
                            }}
                            key={team._id}
                            label={team.name}
                            sx={styles.chipRoot}
                          />
                        ))}
                        {activeUser.teams.length > 3 && (
                          <Box sx={styles.badge}>
                            +
                            {activeUser.teams.length - 3}
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {orgTeams.length > 0 ? orgTeams.map((team) => (
                    <MenuItem
                      sx={{ selected: styles.selected }}
                      key={team._id}
                      onClick={() => handleClick(team._id, team.name)}
                      value={team.name}
                    >
                      <Checkbox sx={{ checked: styles.checked }} checked={activeUser.teams.filter((el: ITeam) => team.name === el.name).length > 0} />
                      {team.name}
                    </MenuItem>
                  )) : (
                    <MenuItem disabled value="">
                      <em>There are no teams to show</em>
                    </MenuItem>
                  )}
                </Select>
              </ThemeProvider>
              <Box sx={styles.modalText}>
                <Typography variant="h4" sx={styles.acceessText}>Access</Typography>
              </Box>
              <FormGroup sx={styles.checkboxes}>
                <FormControlLabel
                  control={<Switch checked={adminChecked} onChange={toggleAdminChecked} color="primary" />}
                  label="ADMIN"
                />
                {_ADMIN_ && (
                  <FormControlLabel
                    control={<Switch checked={pocChecked} disabled={pocEmail === activeUser.email} onChange={togglePocChecked} color="primary" />}
                    label="POC"
                  />
                )}
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={userFormMode === 'add' ? (hasSubmitErrors || submitting) : (pristine && disableUpdateUserButton)}
              >
                {userFormMode === 'add' ? 'ADD NEW USER' : 'UPDATE USER'}
              </Button>
              {loading && <CircularProgress size={24} sx={styles.buttonProgress} />}
            </form>
          )}
        />

      </Dialog>
    </>
  );
};

export default UserForm;
