// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIALs

import { createAction } from '@reduxjs/toolkit';

export const LoadListStartAction = createAction('admin/LoadListAction');
export const LoadListSuccessAction = createAction<{ list: any, count: number }>('admin/LoadListSuccessAction');
export const LoadListFailureAction = createAction(<string>'admin/LoadListFailureAction');

export const SetPageAction = createAction<number>('admin/SetPageAction');

export const SetListAction = createAction<IAdminUser[]>('admin/SetListAction');

export const SetLoadAllAction = createAction<boolean>('admin/SetLoadAllAction');

export const SetLoadNewAction = createAction<boolean>('admin/SetLoadNewAction');

export const UpdateCostPerAcreAction = createAction<number>('admin/UpdateCostPerAcreAction');

export const GetOrgAction = createAction<IOrg>('admin/GetOrgAction');

export const SetOrgIdAction = createAction<string>('admin/SetOrgIdAction');

export const GetOrgProjectsStartAction = createAction('admin/GetOrgProjectsStartAction');
export const GetOrgProjectsSuccessAction = createAction<IAdminProject[]>('admin/GetOrgProjectsSuccessAction');
export const GetOrgProjectsFailureAction = createAction<string>('admin/GetOrgProjectsFailureAction');

export const DeleteProjectAdminStartAction = createAction('admin/DeleteProjectAdminStartAction');
export const DeleteProjectAdminSuccessAction = createAction<string>('admin/DeleteProjectAdminSuccessAction');
export const DeleteProjectAdminFailureAction = createAction<string>('admin/DeleteProjectAdminFailureAction');

export const CreateOrgStartAction = createAction('admin/CreateOrgStartAction');
export const CreateOrgSuccessAction = createAction<IOrg>('admin/CreateOrgSuccessAction');
export const CreateOrgFailureAction = createAction<string>('admin/CreateOrgFailureAction');

export const GetSubscriptionStartAction = createAction('admin/GetSubscriptionStartAction');
export const GetSubscriptionSuccessAction = createAction<ISubscriptions>('admin/GetSubscriptionSuccessAction');
export const GetSubscriptionFailureAction = createAction<string>('admin/GetSubscriptionFailureAction');
export const GetOrgCreditCardsAction = createAction<ICreditCard[]>('admin/GetOrgCreditCardsAction');

export const UpdateOrgSuccessAction = createAction<IOrg>('admin/UpdateOrgSuccessAction');
export const UpdateOrgFailureAction = createAction<string>('admin/UpdateOrgFailureAction');

export const GetUsersStartAction = createAction('admin/GetUsersStartAction');
export const GetUsersSuccessAction = createAction<IUser[]>('admin/GetUsersSuccessAction');

export const GetLastLoginStartAction = createAction('admin/GetLastLoginStartAction');
export const GetLastLoginSuccessAction = createAction<{ lastLogin: string }>('admin/GetLastLoginSuccessAction');
export const GetLastLoginFailureAction = createAction<string>('admin/GetLastLoginFailureAction');

export const GetLoginStatsStartAction = createAction('admin/GetLoginStatsStartAction');
export const GetLoginStatsSuccessAction = createAction<{ thisMonth: any, perMonth: any }>('admin/GetLoginStatsSuccessAction');
export const GetLoginStatsFailureAction = createAction<string>('admin/GetLoginStatsFailureAction');

export const DeleteOrgAction = createAction<string>('admin/DeleteOrgAction');

export const OrgEmailsSuccessAction = createAction<boolean>('admin/OrgEmailsSuccessAction');
export const OrgEmailsFailureAction = createAction<string>('admin/OrgEmailsFailureAction');

export const GetPricingAction = createAction<IPricingTier[]>('admin/GetPricingAction');

export const SetOrgPricingStartAction = createAction('admin/SetOrgPricingStartAction');
export const SetOrgPricingSuccessAction = createAction<IPricingPlanInfo>('admin/SetOrgPricingSuccessAction');
export const SetOrgPricingFailureAction = createAction<string>('admin/SetOrgPricingFailureAction');

export const SetRowsPerPageAction = createAction<number>('admin/SetRowsPerPageAction');

export const SetSearchFilterAction = createAction<string>('admin/SetSearchFilterAction');

export const SetUserIdAction = createAction<string>('admin/SetUserIdAction');

export const SetSortFilterAction = createAction<string>('admin/SetSortFilterAction');
